body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
.highlighted-green {
  background-color: #12d940;
  color: #ffffff;
  font-weight: bold;
}
.highlighted-red {
  background-color: #f44336;
  color: #ffffff;
  font-weight: bold;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
[class^=ant-]::-ms-clear,
[class*= ant-]::-ms-clear,
[class^=ant-] input::-ms-clear,
[class*= ant-] input::-ms-clear,
[class^=ant-] input::-ms-reveal,
[class*= ant-] input::-ms-reveal {
  display: none;
}
/* stylelint-disable property-no-vendor-prefix, at-rule-no-vendor-prefix */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: 'tnum';
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #12d940;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #37e65a;
}
a:active {
  color: #05b334;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a:focus {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::selection {
  color: #fff;
  background: #12d940;
}
.clearfix::before {
  display: table;
  content: '';
}
.clearfix::after {
  display: table;
  clear: both;
  content: '';
}
.anticon {
  display: inline-flex;
  align-items: center;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon > .anticon {
  line-height: 0;
  vertical-align: 0;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin,
.anticon-spin::before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
.ant-fade-enter,
.ant-fade-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-fade-enter.ant-fade-enter-active,
.ant-fade-appear.ant-fade-appear-active {
  animation-name: antFadeIn;
  animation-play-state: running;
}
.ant-fade-leave.ant-fade-leave-active {
  animation-name: antFadeOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-fade-enter,
.ant-fade-appear {
  opacity: 0;
  animation-timing-function: linear;
}
.ant-fade-leave {
  animation-timing-function: linear;
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ant-move-up-enter,
.ant-move-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-up-enter.ant-move-up-enter-active,
.ant-move-up-appear.ant-move-up-appear-active {
  animation-name: antMoveUpIn;
  animation-play-state: running;
}
.ant-move-up-leave.ant-move-up-leave-active {
  animation-name: antMoveUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-up-enter,
.ant-move-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-up-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-down-enter,
.ant-move-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-down-enter.ant-move-down-enter-active,
.ant-move-down-appear.ant-move-down-appear-active {
  animation-name: antMoveDownIn;
  animation-play-state: running;
}
.ant-move-down-leave.ant-move-down-leave-active {
  animation-name: antMoveDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-down-enter,
.ant-move-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-down-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-left-enter,
.ant-move-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-left-enter.ant-move-left-enter-active,
.ant-move-left-appear.ant-move-left-appear-active {
  animation-name: antMoveLeftIn;
  animation-play-state: running;
}
.ant-move-left-leave.ant-move-left-leave-active {
  animation-name: antMoveLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-left-enter,
.ant-move-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-left-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-right-enter,
.ant-move-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-right-enter.ant-move-right-enter-active,
.ant-move-right-appear.ant-move-right-appear-active {
  animation-name: antMoveRightIn;
  animation-play-state: running;
}
.ant-move-right-leave.ant-move-right-leave-active {
  animation-name: antMoveRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-right-enter,
.ant-move-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-right-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html {
  --antd-wave-shadow-color: #12d940;
  --scroll-bar: 0;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #12d940;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #12d940;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-up-enter.ant-slide-up-enter-active,
.ant-slide-up-appear.ant-slide-up-appear-active {
  animation-name: antSlideUpIn;
  animation-play-state: running;
}
.ant-slide-up-leave.ant-slide-up-leave-active {
  animation-name: antSlideUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-up-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-down-enter.ant-slide-down-enter-active,
.ant-slide-down-appear.ant-slide-down-appear-active {
  animation-name: antSlideDownIn;
  animation-play-state: running;
}
.ant-slide-down-leave.ant-slide-down-leave-active {
  animation-name: antSlideDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-left-enter.ant-slide-left-enter-active,
.ant-slide-left-appear.ant-slide-left-appear-active {
  animation-name: antSlideLeftIn;
  animation-play-state: running;
}
.ant-slide-left-leave.ant-slide-left-leave-active {
  animation-name: antSlideLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-left-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-right-enter.ant-slide-right-enter-active,
.ant-slide-right-appear.ant-slide-right-appear-active {
  animation-name: antSlideRightIn;
  animation-play-state: running;
}
.ant-slide-right-leave.ant-slide-right-leave-active {
  animation-name: antSlideRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-right-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
.ant-zoom-enter,
.ant-zoom-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-enter.ant-zoom-enter-active,
.ant-zoom-appear.ant-zoom-appear-active {
  animation-name: antZoomIn;
  animation-play-state: running;
}
.ant-zoom-leave.ant-zoom-leave-active {
  animation-name: antZoomOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-enter,
.ant-zoom-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-enter-prepare,
.ant-zoom-appear-prepare {
  transform: none;
}
.ant-zoom-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-enter.ant-zoom-big-enter-active,
.ant-zoom-big-appear.ant-zoom-big-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.ant-zoom-big-leave.ant-zoom-big-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-enter-prepare,
.ant-zoom-big-appear-prepare {
  transform: none;
}
.ant-zoom-big-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-fast-leave {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
.ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-fast-enter-prepare,
.ant-zoom-big-fast-appear-prepare {
  transform: none;
}
.ant-zoom-big-fast-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-up-enter.ant-zoom-up-enter-active,
.ant-zoom-up-appear.ant-zoom-up-appear-active {
  animation-name: antZoomUpIn;
  animation-play-state: running;
}
.ant-zoom-up-leave.ant-zoom-up-leave-active {
  animation-name: antZoomUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-up-enter-prepare,
.ant-zoom-up-appear-prepare {
  transform: none;
}
.ant-zoom-up-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-down-enter.ant-zoom-down-enter-active,
.ant-zoom-down-appear.ant-zoom-down-appear-active {
  animation-name: antZoomDownIn;
  animation-play-state: running;
}
.ant-zoom-down-leave.ant-zoom-down-leave-active {
  animation-name: antZoomDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-down-enter-prepare,
.ant-zoom-down-appear-prepare {
  transform: none;
}
.ant-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-left-enter.ant-zoom-left-enter-active,
.ant-zoom-left-appear.ant-zoom-left-appear-active {
  animation-name: antZoomLeftIn;
  animation-play-state: running;
}
.ant-zoom-left-leave.ant-zoom-left-leave-active {
  animation-name: antZoomLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-left-enter-prepare,
.ant-zoom-left-appear-prepare {
  transform: none;
}
.ant-zoom-left-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-right-enter.ant-zoom-right-enter-active,
.ant-zoom-right-appear.ant-zoom-right-appear-active {
  animation-name: antZoomRightIn;
  animation-play-state: running;
}
.ant-zoom-right-leave.ant-zoom-right-leave-active {
  animation-name: antZoomRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-right-enter-prepare,
.ant-zoom-right-appear-prepare {
  transform: none;
}
.ant-zoom-right-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header3 {
  background: #fff;
  width: 100%;
  z-index: 1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
}
.header3 .home-page {
  padding: 0 24px;
}
.header3-logo {
  display: inline-block;
  position: relative;
  line-height: 64px;
}
.header3-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header3-logo a {
  display: block;
}
.header3-menu {
  float: right;
}
.header3-menu .ant-menu-horizontal {
  border-bottom: none;
}
.header3-menu .ant-menu {
  line-height: 62px;
  height: 64px;
  background: transparent;
}
.header3-menu .ant-menu a {
  display: block;
}
.header3-item-block {
  padding: 0 8px;
}
.header3-item-block > * {
  display: inline-block;
}
.header3-item .ant-menu-sub .ant-menu-item,
.header3-item-child .ant-menu-sub .ant-menu-item,
.header3-menu .ant-menu-sub .ant-menu-item,
.header3-item .ant-menu-inline .ant-menu-item,
.header3-item-child .ant-menu-inline .ant-menu-item,
.header3-menu .ant-menu-inline .ant-menu-item {
  height: auto;
  line-height: 1.5;
}
.header3-item .item-sub-item,
.header3-item-child .item-sub-item,
.header3-menu .item-sub-item {
  display: block;
  padding: 8px 24px;
}
.header3-item .item-image,
.header3-item-child .item-image,
.header3-menu .item-image {
  float: left;
  margin-right: 16px;
  margin-top: 4px;
  position: relative;
  z-index: 1;
}
.header3-item .item-title,
.header3-item-child .item-title,
.header3-menu .item-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 46px;
}
.header3-item .item-content,
.header3-item-child .item-content,
.header3-menu .item-content {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
  margin-left: 46px;
}
@media screen and (max-width: 767px) {
  .header3-logo {
    z-index: 101;
  }
  .header3.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header3-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background: #fff;
  }
  .header3-menu li {
    padding: 0 24px;
  }
  .header3-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header3-menu .item-sub-item {
    padding: 8px 0;
  }
  .header3-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header3-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #001529;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header3-mobile-menu :first-child {
    margin-top: 0;
  }
  .header3 .ant-menu {
    height: auto;
    overflow: hidden;
  }
  .header3 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header3 .open {
    height: auto;
  }
  .header3 .open .header3-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header3 .open .header3-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header3 .open .header3-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header3 .open > .header3-menu {
    opacity: 1;
    pointer-events: auto;
  }
  .header3-item-block {
    height: 40px;
    line-height: 40px;
  }
}
.banner5 {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #fdfdfd;
  background-image: linear-gradient(to top, rgba(253, 253, 253, 0.3) 0%, rgba(193, 218, 255, 0.3) 100%);
  overflow: hidden;
}
.banner5 .banner5-page {
  padding: 1px 0 0;
}
.banner5-title {
  font-size: 50px;
  font-weight: 500;
  line-height: 1.3;
  color: #12D940;
  margin-bottom: 8px;
}
.banner5-title-wrapper {
  width: 40%;
  max-width: 450px;
  position: relative;
  top: 30%;
  left: 24px;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.banner5-explain {
  color: #333;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 24px;
}
.banner5-content {
  font-size: 18px;
  color: #666;
  margin-bottom: 48px;
}
.banner5-button-wrapper .banner5-button {
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
  box-shadow: 0 8px 24px rgba(95, 155, 241, 0.35);
  border-radius: 20px;
  border: none;
  padding: 0 42px;
  line-height: 40px;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  transition: transform 0.3s, box-shadow 0.3s;
}
.banner5-button-wrapper .banner5-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 13px 24px rgba(95, 155, 241, 0.45);
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
}
.banner5-image {
  overflow: hidden;
  width: 60%;
  max-width: 800px;
  position: absolute;
  top: 15%;
  right: 24px;
}
@media screen and (max-width: 767px) {
  .banner5 {
    min-height: 500px;
    padding-top: 32px;
  }
  .banner5 .banner5-page.home-page {
    padding-top: 1px;
    padding-bottom: 0;
  }
  .banner5-title-wrapper {
    text-align: center;
    position: static;
    width: 100%;
    margin-top: 64px;
    left: 0;
    top: 0;
  }
  .banner5-title {
    font-size: 32px;
  }
  .banner5-explain {
    font-size: 18px;
  }
  .banner5-content {
    font-size: 14px;
  }
  .banner5-image {
    position: static;
    width: 100%;
    top: 0;
    right: 0;
    margin: 32px 0 24px;
  }
}
.content0-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.content0-wrapper .content0 {
  height: 100%;
  padding: 64px 24px;
}
.content0-wrapper .content0 > .title-wrapper {
  margin: 0 auto 48px;
}
.content0-wrapper .content0-block {
  padding: 0 4%;
  display: inline-block;
  text-align: center;
  min-height: 200px;
  margin-bottom: 24px;
}
.content0-wrapper .content0-block img {
  width: 50%;
  border-radius: 20px;
}
.content0-wrapper .content0-block-wrapper {
  position: relative;
  height: 100%;
  top: 25%;
  padding: 20px 0;
}
.content0-wrapper .content0-block.queue-anim-leaving {
  position: relative !important;
}
.content0-wrapper .content0-block-icon {
  width: 100px;
  font-size: 50px;
  height: 100px;
  color: #12D940;
  margin: auto;
}
.content0-wrapper .content0-block-title {
  line-height: 32px;
  margin: 10px auto;
  font-size: 24px;
}
@media screen and (max-width: 767px) {
  .content0-wrapper {
    min-height: 880px;
  }
}
.content1-wrapper {
  height: 360px;
  background-color: #F7F7F8;
  padding-top: 10px;
}
.content1-wrapper .content1 {
  height: 100%;
  padding: 0 24px;
}
.content1-wrapper .content1-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content1-wrapper .content1-img span {
  display: block;
  width: 250px;
}
.content1-wrapper .content1-img span img {
  display: block;
}
.content1-wrapper .content1-text {
  height: 100%;
}
.content1-wrapper .content1-text .content1-content,
.content1-wrapper .content1-text .content1-title {
  position: relative !important;
}
.content1-wrapper .content1-text .content1-title {
  font-size: 32px;
  font-weight: normal;
  color: #404040;
  margin-top: 120px;
}
.content1-wrapper .content1-text .content {
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .content1-wrapper {
    height: auto;
  }
  .content1-wrapper .content1-img {
    height: 200px;
    padding: 0;
    text-align: center;
    margin-top: 64px;
  }
  .content1-wrapper .content1-img span {
    display: inline-block;
    width: 180px;
    height: 200px;
    line-height: 200px;
    margin: auto;
  }
  .content1-wrapper .content1-text {
    height: auto;
    margin-bottom: 20px;
    text-align: center;
    background-color: #F7F7F8;
    opacity: 90%;
    padding: 0;
  }
  .content1-wrapper .content1-text .content1-content,
  .content1-wrapper .content1-text .content1-title {
    width: 100%;
    top: auto;
  }
  .content1-wrapper .content1-text .content1-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.content3-wrapper {
  min-height: 764px;
}
.content3-wrapper .content3 {
  height: 100%;
  overflow: hidden;
}
.content3-wrapper .content3 .title-content {
  text-align: center;
}
.content3-wrapper .content3 .highlighted-green {
  background-color: #12d940;
  color: #ffffff;
  font-weight: bold;
}
.content3-wrapper .content3 .highlighted-red {
  background-color: #f44336;
  color: #ffffff;
  font-weight: bold;
}
.content3-wrapper .content3-icon {
  height: 50px;
  width: 49px;
}
.content3-wrapper .content3-block-wrapper {
  position: relative;
}
.content3-wrapper .content3-block-wrapper .content3-block {
  display: inline-block;
  padding: 48px 24px;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-icon {
  display: inline-block;
  width: 15%;
  vertical-align: top;
}
.content3-wrapper .content3-block-wrapper .content3-block .content3-text {
  width: 85%;
  display: inline-block;
  padding-left: 8%;
}
.content3-wrapper .content3-block-wrapper .content3-block.clear-both {
  clear: both;
}
@media screen and (max-width: 767px) {
  .content3-wrapper {
    min-height: 1080px;
  }
  .content3-wrapper .content3-block-wrapper {
    margin: 20px auto;
    height: auto;
  }
  .content3-wrapper .content3-block-wrapper .content3-block .content3-title {
    font-size: 20px;
  }
  .content3-wrapper .content3-block-wrapper .content3-block.queue-anim-leaving {
    position: relative !important;
  }
}
.pricing0-wrapper {
  background-color: #F7F7F8;
  padding-top: 10px;
  width: 100vw;
}
.pricing0-wrapper .pricing0 {
  min-height: 370px;
  padding: 0 24px;
  display: flex;
  align-items: flex-end;
}
.pricing0-wrapper .pricing0-img-wrapper {
  height: 100%;
  transform-origin: top;
  padding: 0 32px;
}
.pricing0-wrapper .pricing0-img-wrapper .pricing0-img {
  display: block;
  width: 100%;
  max-width: 560px;
}
.pricing0-wrapper .pricing0-img-wrapper .pricing0-img img {
  display: block;
}
.pricing0-wrapper .pricing0-button-wrapper .pricing0-button {
  background-image: linear-gradient(to right bottom, #D0FBDA 0%, #12D940 0%);
  box-shadow: 0 8px 24px rgba(18, 217, 64, 0.35);
  border-radius: 20px;
  border: none;
  padding: 0 42px;
  line-height: 40px;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
  transition: transform 0.3s, box-shadow 0.3s;
}
.pricing0-wrapper .pricing0-button-wrapper .pricing0-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 13px 24px rgba(18, 217, 64, 0.45);
  background-image: linear-gradient(to right bottom, #D0FBDA 0%, #12D940 0%);
}
.pricing0-wrapper .pricing0-text-wrapper {
  min-height: 320px;
  padding: 0 40px;
  max-width: 560px;
  margin-bottom: 32px;
}
.pricing0-wrapper .pricing0-text-wrapper .pricing0-content,
.pricing0-wrapper .pricing0-text-wrapper .pricing0-title {
  position: relative !important;
}
.pricing0-wrapper .pricing0-text-wrapper .pricing0-title {
  font-size: 24px;
  font-weight: normal;
  color: #404040;
  margin: 72px auto 16px;
}
.pricing0-wrapper .pricing0-text-wrapper .pricing0-content {
  font-size: 12px;
  color: #666;
  line-height: 1.5;
}
.pricing0-wrapper .pricing0-text-wrapper .pricing0-pricing {
  font-size: 36px;
  color: #404040;
  margin: 32px 0 24px;
}
@media screen and (max-width: 767px) {
  .pricing0-wrapper {
    min-height: 720px;
  }
  .pricing0-wrapper .pricing0 {
    display: block;
  }
  .pricing0-wrapper .pricing0-img-wrapper {
    padding: 0;
    text-align: center;
    margin-top: 24px;
  }
  .pricing0-wrapper .pricing0-img-wrapper .pricing0-img {
    display: inline-block;
    width: 80%;
    margin: auto;
  }
  .pricing0-wrapper .pricing0-text-wrapper {
    height: auto;
    text-align: center;
    padding: 0;
    max-width: 100%;
  }
  .pricing0-wrapper .pricing0-text-wrapper .pricing0-content,
  .pricing0-wrapper .pricing0-text-wrapper .pricing0-title {
    width: 100%;
    top: auto;
  }
  .pricing0-wrapper .pricing0-text-wrapper .pricing0-title {
    margin: 32px auto 16px;
    font-size: 24px;
  }
}
.pricing2-wrapper {
  min-height: 760px;
}
.pricing2-wrapper .pricing2 > p {
  text-align: center;
}
.pricing2-wrapper .pricing2-content-wrapper {
  min-height: 400px;
}
.pricing2-wrapper .pricing2-table-name-block {
  text-align: center;
  color: #666;
  width: 100%;
}
.pricing2-wrapper .pricing2-table-name {
  font-size: 24px;
}
.pricing2-wrapper .pricing2-table-money {
  font-size: 16px;
  margin: 8px 0 16px;
}
.pricing2-wrapper .pricing2-table-content {
  text-align: center;
  color: #666;
}
.pricing2-wrapper .pricing2-table-content-name {
  color: #666;
  text-align: center;
}
.pricing2-wrapper.home-page-wrapper .pricing2-title-wrapper {
  margin-bottom: 64px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .pricing2-wrapper {
    padding-bottom: 0;
  }
  .pricing2-table {
    margin-bottom: 24px;
  }
}
.footer0-wrapper {
  background-color: #001529;
  height: 80px;
  overflow: hidden;
}
.footer0-wrapper .footer0 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #999;
  position: relative;
}
@media screen and (max-width: 767px) {
  .footer0-wrapper .footer0 {
    font-size: 12px;
  }
  .footer0-wrapper .footer0.home-page {
    padding: 0;
  }
  .footer0-wrapper .footer0 > div {
    width: 90%;
    margin: auto;
  }
}
#Banner5_0 .lxhu3z0wvk-editor_css {
  color: #3e5931;
}
#Banner5_0.lxhu49rdaj-editor_css {
  background-image: linear-gradient(to top, rgba(253, 253, 253, 0.3) 0%, rgba(208, 251, 218, 0.5) 100%);
}
#Banner5_0 .home-page > .banner5-title-wrapper > .lxhu5swvou6-editor_css {
  color: #12d940;
}
#Banner5_0 .banner5-title-wrapper > .banner5-button-wrapper > .lxhuqnkv4ja-editor_css {
  background-color: #12d940;
  background-image: linear-gradient(135deg, #12d940 0.93%, #12d940 100%, #12d940 100%, #12d940 100%, black 100%);
  box-shadow: 0px 8px 24px #d0fbda;
}
#Pricing0_0 .ant-col > .lxi348ivcc8-editor_css > .lxi344ecwbr-editor_css {
  background-color: #12d940;
}
#Content1_0 .ant-row > .ant-col > .lxi361dx6bt-editor_css {
  font-weight: 500;
}
