#Banner5_0 .lxhu3z0wvk-editor_css {
  color: #3e5931;
}
#Banner5_0.lxhu49rdaj-editor_css {
  background-image: linear-gradient(
    to top,
    rgba(253, 253, 253, 0.3) 0%,
    rgba(208, 251, 218, 0.5) 100%
  );
}
#Banner5_0 .home-page > .banner5-title-wrapper > .lxhu5swvou6-editor_css {
  color: #12d940;
}
#Banner5_0
  .banner5-title-wrapper
  > .banner5-button-wrapper
  > .lxhuqnkv4ja-editor_css {
  background-color: rgb(18, 217, 64);
  background-image: linear-gradient(
    135deg,
    rgb(18, 217, 64) 0.93%,
    rgb(18, 217, 64) 100%,
    rgb(18, 217, 64) 100%,
    rgb(18, 217, 64) 100%,
    black 100%
  );
  box-shadow: 0px 8px 24px #d0fbda;
}
#Pricing0_0 .ant-row > .ant-col > .lxi348ivcc8-editor_css {
}
#Pricing0_0 .ant-col > .lxi348ivcc8-editor_css > .lxi344ecwbr-editor_css {
  background-color: #12d940;
}
#Content1_0 .ant-row > .ant-col > .lxi361dx6bt-editor_css {
  font-weight: 500;
}
