@pricing0: pricing0;
.@{pricing0}-wrapper {
  background-color: #F7F7F8;
    padding-top: 10px;
    width: 100vw;
  .@{pricing0} {
    min-height: 370px;
    padding: 0 24px;
    display: flex;
    align-items: flex-end;
    
    &-img-wrapper {
      height: 100%;
      transform-origin: top;
      padding: 0 32px;
      .@{pricing0}-img {
        display: block;
        width: 100%;
        max-width: 560px;
        img {
          display: block;
        }
      }
    }
    &-button-wrapper {
      .@{pricing0}-button {
        background-image: linear-gradient(to right bottom, #D0FBDA 0%, #12D940 0%);
        box-shadow: 0 8px 24px fade(#12D940, 35);
        border-radius: 20px;
        border: none;
        padding: 0 42px;
        line-height: 40px;
        height: 40px;
        font-size: 20px;
        font-weight: bold;
        transition: transform .3s, box-shadow .3s;
        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 13px 24px fade(#12D940, 45);
          background-image: linear-gradient(to right bottom, #D0FBDA 0%, #12D940 0%);
        }
      }
    }
    
    &-text-wrapper {
      min-height: 320px;
      padding: 0 40px;
      max-width: 560px;
      margin-bottom: 32px;
      .@{pricing0}-content,
      .@{pricing0}-title {
        position: relative !important;
      }
      
      .@{pricing0}-title {
        font-size: 24px;
        font-weight: normal;
        color: #404040;
        margin: 72px auto 16px;
      }
      .@{pricing0}-content {
        font-size: 12px;
        color: #666;
        line-height: 1.5;
      }
      .@{pricing0}-pricing {
        font-size: 36px;
        color: #404040;
        margin: 32px 0 24px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{pricing0}-wrapper {
    min-height: 720px;
    .@{pricing0} {
      display: block;
      &-img-wrapper {
        padding: 0;
        text-align: center;
        margin-top: 24px;
        .@{pricing0}-img {
          display: inline-block;
          width: 80%;
          margin: auto;
        }
      }
      &-text-wrapper {
        height: auto;
        text-align: center;
        padding: 0;
        max-width: 100%;
        .@{pricing0}-content,
        .@{pricing0}-title {
          width: 100%;
          top: auto;
        }
        .@{pricing0}-title {
          margin: 32px auto 16px;
          font-size: 24px;
        }
      }
    }
  }
}
