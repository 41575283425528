@content0: content0;

.@{content0}-wrapper {
  min-height: 446px;
  overflow: hidden;

  .@{content0} {
    height: 100%;
    padding: 64px 24px;

    >.title-wrapper {
      margin: 0 auto 48px;
    }

    &-block {
      padding: 0 4%;
      display: inline-block;
      text-align: center;
      min-height: 200px;
      margin-bottom: 24px;
      img {
        width: 50%;
        border-radius: 20px;
      }

      &-wrapper {
        position: relative;
        height: 100%;
        top: 25%;
        padding: 20px 0;
      }

      &.queue-anim-leaving {
        position: relative !important;
      }

      &-icon {
        width: 100px;
        font-size: 50px;
        height: 100px;
        color: #12D940;
        margin: auto;
      }

      &-title {
        line-height: 32px;
        margin: 10px auto;
        font-size: 24px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{content0}-wrapper {
    min-height: 880px;
  }
}
